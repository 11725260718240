import React from "react";

import { Link } from "gatsby";

import { ReactComponent as Phone } from "../img/noun_Phone_52971_footer.svg";
import { ReactComponent as Mail } from "../img/noun_Letter_1009550_footer.svg";
import { ReactComponent as Pin } from "../img/noun_map pin_484145_footer.svg";
import { ReactComponent as Fax } from "../img/noun_fax_3592982_footer.svg";

const Footer = ({ herosection, content, contact, footer }) => {
  const telephoneLink =
    "tel:+49" + herosection.telephone.replace(/\D/g, "").slice(1);

  let strAddress = herosection.address;
  let indexAddress = strAddress.indexOf("90");
  let firstChunkAddress = strAddress.substr(0, indexAddress);
  let secondChunkAddress = strAddress.substr(indexAddress);
  secondChunkAddress = secondChunkAddress.substring(
    0,
    secondChunkAddress.length - 1
  );

  let strEmail = herosection.email;
  let indexEmail = strEmail.indexOf("@");
  let firstChunkEmail = strEmail.substr(0, indexEmail);
  let secondChunkEmail = strEmail.substr(indexEmail);

  return (
    <footer class="px-20p sm:px-10 py-50p sm:py-80p bg-footerBg text-custom-grey text-09697 sm:text-10909">
      <div class="text-center max-w-900p mx-auto">
        <h4 class="sm:hidden font-medium text-12121 mb-35p">
          {herosection.contactText}
        </h4>
        <div class="sm:hidden flex justify-center space-x-5 mb-35p">
          <a href={`tel:+${telephoneLink}`}>
            <Phone class="w-25p" />
          </a>
          <a
            href={`mailto:${herosection.email}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Mail class="w-25p" />
          </a>
          <a
            href={contact.googleMapsLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Pin class="w-25p" />
          </a>
        </div>
        <div class="sm:hidden flex justify-center space-x-5 mb-35p">
          <Link class="font-semibold" to="/impressum">
            Impressum
          </Link>
          <Link class="font-semibold" to="/datenschutz">
            Datenschutz
          </Link>
        </div>
        <div class="hidden sm:flex justify-around lg:justify-between mb-60p">
          <nav class="flex flex-col text-left space-y-2">
            <Link to="/">Start</Link>
            {content !== "" && <Link to="/#aktuelles">Aktuelles</Link>}
            <Link to="/#leistungen">Leistungen</Link>
            <Link to="/#kontakt">Kontakt</Link>
            <Link to="/praxisteam">Praxisteam</Link>
            <Link to="/infomaterial">Infomaterial</Link>
            <Link to="/qualitaetsmanagement">Qualitätsmanagement</Link>
            <Link to="/impressum">Impressum</Link>
            <Link to="/datenschutz">Datenschutz</Link>
          </nav>
          <div class="hidden lg:block text-left">
            <h4 class="mb-20p font-medium text-15152">
              {contact.openingHours.title}
            </h4>
            <table>
              {contact.openingHours.days.map((item) => (
                <tr class="p-0 leading-6">
                  <td class="p-0 pr-2">{item.day}</td>

                  {item.times[0].time !== "geschlossen" ? (
                    <React.Fragment>
                      <td class="p-0 pr-2">{item.times[0].time}</td>
                      {item.times[1] && (
                        <React.Fragment>
                          <td class="p-0 pr-2">&</td>
                          <td class="p-0">{item.times[1].time}</td>
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <td class="p-0 pr-2"></td>
                      <td class="p-0 pr-2"></td>
                      {item.times[1] && (
                        <td class="p-0">{item.times[1].time}</td>
                      )}
                    </React.Fragment>
                  )}
                </tr>
              ))}
            </table>
            <div class="block text-left mb-40p">
              {contact.openingHours.description}
            </div>
            <div class="space-y-2 md:space-y-0">
              <h5 class="font-medium pb-1">
                {contact.earlyHours.title}
              </h5>
              {contact.earlyHours.times.map((el)=>(
                <p class="pb-1">{el}</p>
              ))}
            </div>
          </div>
          <div class="text-left space-y-3">
            <h4 class="mb-20p font-medium text-15152">
              {herosection.contactText}
            </h4>
            <a
              href="https://goo.gl/maps/ALovXrurRFoGRPFP9"
              target="_blank"
              rel="noopener noreferrer"
              class="flex items-start w-full"
            >
              <Pin class="w-25p mr-3 mt-0.5" />
              <div>
                <div class="w-full">
                  {firstChunkAddress} <br /> {secondChunkAddress}
                </div>
              </div>
            </a>
            <a
              href={`mailto:${herosection.email}`}
              target="_blank"
              rel="noopener noreferrer"
              class="flex items-start w-full mb-2"
            >
              <Mail class="w-25p mr-3 mt-0.5" />
              <div>
                <div class="w-full">
                  {firstChunkEmail} <br /> {secondChunkEmail}
                </div>
              </div>
            </a>
            <a
              href={`tel:${herosection.telephoneLink}`}
              class="flex items-center w-full mb-2"
            >
              <Phone class="w-25p mr-3" />
              <div>
                <div class="w-full">{herosection.telephone}</div>
              </div>
            </a>
            <div class="flex items-center w-full">
              <Fax class="w-25p mr-3" />
              <div>
                <div class="w-full">{herosection.fax}</div>
              </div>
            </div>
          </div>
        </div>
        <div>{`${new Date().getFullYear()} ${footer.copyright}`}</div>
        <a href="https://agenturignis.de">
          Webdesign & Betreuung: Agentur ignis
        </a>
      </div>
    </footer>
  );
};

export default Footer;
